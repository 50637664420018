<template>
  <div>
    <div>
      <v-data-table
        :headers="headers"
        :items="tableData"
        class="app-events-table "
        :loading="LOADING"
        :items-per-page="rowsPerPage"
        :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
        @page-count="pageCount = $event"
        :page.sync="C_PAGE"
        header-props.sort-icon="mdi-chevron"
      >
        <template v-slot:no-data>
          No results match your current filters, please try again.
        </template>
        <template #loading>
          <v-progress-circular
            class="centered-circle"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
        <template v-slot:item.eventStartDate="{ item }">
          <span>{{
            item.eventStartDate | humanTableTimeFormat(item.timezone)
          }}</span>
        </template>
        <template v-slot:item.eventEndDate="{ item }">
          <span>{{
            item.eventEndDate | humanTableTimeFormat(item.timezone)
          }}</span>
        </template>
        <template v-slot:item.organization="{ item }">
          <span v-show="!item.organization || !item.organization.length">-</span>
          <span v-for="(o, idx) in item.organization" :key="o.id">
            {{ o | humanOrganizationFilter
            }}<template v-if="idx < item.organization.length - 1">,<br/></template>
          </span>
        </template>

        <template v-slot:item.name="{ item }">
          <router-link class="event-name" :to="'/event/edit/' + item.id">{{
            item.name
          }}</router-link>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="flex-wrap justify-center d-flex flex-lg-nowrap">
            <app-button
              v-if="displayViewButton(item) && displayQRCodeButton(item)"
              :to="`/webqr/${item.id}`"
              class="ma-1 flex-grow-1"
              target="_blank"
            >
              QR Code
            </app-button>
            <app-button
              v-else-if="displayQRCodeButton(item)"
              :to="`/webqr/preview/${item.id}`"
              class="ma-1 flex-grow-1"
              outlined
              target="_blank"
            >
              QR Code
            </app-button>
            <app-button
              v-if="displayViewButton(item)"
              :to="`/engagements/register/${item.id}`"
              class="ma-1 flex-grow-1"
              target="_blank"
            >
              Launch
            </app-button>
            <app-button
              v-else
              :to="`/engagements/preview/${item.id}`"
              class="ma-1 flex-grow-1"
              outlined
              target="_blank"
            >
              Preview
            </app-button>
          </div>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="RESET_FILTERS">
            No results found, reset filters.
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import AppButton from './AppButton.vue';

export default {
  name: 'AllEventsTable',
  props: {
    tableData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    rowsPerPage: {
      type: Number,
      default: 25
    }
  },
  components: {
    AppButton
  },
  data() {
    return {
      headers: [
        {
          text: 'Start',
          align: 'cell',

          value: 'eventStartDate',
          width: '5%',
          class: 'abc'
        },
        { text: 'End', value: 'eventEndDate', align: 'cell', width: '5%' },
        { text: 'Event Name', value: 'name', align: 'cell', width: '10%' },
        {
          text: 'TMUS Org',
          value: 'organization',
          align: 'cell',
          width: '10%'
        },
        { text: 'Brand', value: 'brand', align: 'cell', width: '10%' },
        { text: 'Region', value: 'region', align: 'cell', width: '5%' },
        { text: 'State', value: 'state', align: 'cell', width: '10%' },
        { text: 'DMA', value: 'dma', align: 'cell', width: '10%' },
        {
          text: 'Interactions',
          value: 'engagements',
          align: 'cell',
          width: '10%'
        },
        {
          text: 'Actions',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '10%'
        }
      ],
      sortBy: null,
      sortDesc: false,
      currentSort: null,
      currentSortDirection: 'down'
    };
  },
  methods: {
    sortByt(event, fieldName) {
      if (this.currentSort == fieldName) {
        this.currentSortDirection =
          this.currentSortDirection == 'down' ? 'up' : 'down';
      } else {
        this.currentSort = fieldName;
        this.currentSortDirection = 'up';
      }
      this.sortDesc = !this.sortDesc;
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc;
    },
    nextSort() {
      let index = this.headers.findIndex(h => h.value === this.sortBy);
      index = (index + 1) % this.headers.length;
      this.sortBy = this.headers[index].value;
    },
    displayViewButton: event => {
      // if today's date is before the event start date or after the event end date, show the preview button
      if (
        !event.published ||
        event.eventStartDate == null ||
        event.eventStartDate == '' ||
        event.eventEndDate == null ||
        event.eventEndDate == ''
      ) {
        return false;
      } else {
        return true;
      }
    },
    displayQRCodeButton: event => {
      if (event.experience && event.experience === 'Gametime') {
        return false;
      } else {
        return true;
      }
    },
    ...mapActions('EVENT_TABLE_STORE', ['RESET_FILTERS'])
  },
  computed: {
    ...mapState('EVENT_TABLE_STORE', [
      'RESULTS_PER_PAGE',
      'CURRENT_PAGE',
      'NUMBER_OF_PAGES',
      'LOADING'
    ]),
    C_PAGE: {
      get() {
        return this.CURRENT_PAGE;
      },
      set(value) {
        this.$store.commit('EVENT_TABLE_STORE/SET_CURRENT_PAGE', value);
      }
    }
  }
};
</script>
